@font-face {
  font-family: pragmatica, sans-serif;
  src: url("https://use.typekit.net/kux0sgd.css");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: tangarine;
  src: url("./assets/fonts/Tangerine_Bold.ttf");
}

@font-face {
  font-family: Tinos, serif;
  src: url("https://fonts.googleapis.com/css2?family=Tinos:wght@700&display=swap");
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

* {
  font-family: pragmatica;
}

h2 {
  font-family: tangarine;
  font-size: 4.5em;
  margin: 0;
}

p {
  font-size: 1.25em;
}

button {
  font-size: 1.25em;
  background: transparent;
  border-radius: 5px;
  font-weight: 200;
  width: fit-content;
  padding: 10px 20px;
}

button.light:hover,
button.dark:hover {
  border: 3px solid #859c3e;
  color: #859c3e;
}

button.light {
  border: 3px solid #ffffff;
  color: white;
}

button.dark {
  border: 3px solid #333333;
  color: #333333;
}

button.disabled {
  border: 3px solid #cbcbcb;
  color: #cbcbcb;
}

@media (max-width: 375px) {
  h2 {
    font-size: 3.5em;
  }

  p {
    font-size: 1em;
  }

  button {
    font-size: 1em;
  }

  button.light:hover,
  button.dark:hover {
    border: 2px solid #859c3e;
    color: #859c3e;
  }

  button.light {
    border: 2px solid #ffffff;
    color: white;
  }

  button.dark {
    border: 2px solid #333333;
    color: #333333;
  }
}
