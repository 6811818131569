.ContactForm {
  width: 100%;
}
.ContactForm :global .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ContactForm :global form {
  width: 100%;
  margin-top: 40px;
}

.ContactForm :global .formField {
  display: flex;
}

.ContactForm :global .formField.middle {
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}

.ContactForm :global .formLabel {
  width: 15%;
}

.ContactForm :global .formInput {
  width: 40%;
  height: 40px;
}

.ContactForm :global .formInput.message {
  width: 60%;
  height: 200px;
}

.ContactForm :global span {
  color: #db1717;
}
.ContactForm :global .notice {
  font-style: italic;
}

.ContactForm :global .notice.datenschutz {
  padding-left: 15%;
}
.ContactForm :global button {
  margin-left: 15%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
.ContactForm :global label {
  font-size: 1.25em;
}

.ContactForm :global .recaptcha {
  padding-left: 15%;
}

@media (max-width: 1100px) {
  .ContactForm :global .container {
    margin-top: 10%;
    width: 100%;
  }
  .ContactForm :global .formField {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .ContactForm :global .formInput {
    width: 60%;
  }
  .ContactForm :global .formInput.message {
    width: 90%;
  }
  .ContactForm :global .notice.datenschutz {
    padding-left: 0;
  }

  .ContactForm :global .recaptcha {
    padding-left: 0;
  }

  .ContactForm :global button {
    margin-left: 0;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
  }
  .ContactForm :global .formField.middle {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .ContactForm :global .formLabel {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .ContactForm :global label {
    font-size: 1em;
  }

  .ContactForm :global .formInput {
    height: 30px;
  }
}
