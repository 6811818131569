.Pictures :global .container {
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 3%;
}

.Pictures :global .item {
  width: 32.5%;
}

.Pictures :global .pic {
  width: 100%;
  margin-bottom: 2.5%;
}

@media (max-width: 800px) {
  .Pictures :global .container {
    flex-direction: column;
    align-items: center;
  }
  .Pictures :global .item {
    width: 80%;
  }
}
