.Navbar {
  position: fixed;
  top: 0;
  width: 100%;
}

.Navbar :global .container {
  display: flex;
  justify-content: center;
  background-color: #333333;
  flex-wrap: wrap;
  height: fit-content;
}

.Navbar :global .item {
  width: fit-content;
  height: fit-content;
  margin: 15px 25px; /*top right bottom left*/
  /*border: 3px solid #ff00ce;
  background-color: #ffc6f3;*/
}

.Navbar :global .nav {
  background: transparent;
  border: transparent;
  color: white;
  font-weight: 200;
}

.Navbar :global button:hover {
  font-weight: 500;
  text-decoration: underline solid #859c3e 5px;
  text-underline-offset: 10px;
}

@media (max-width: 940px) {
  .Navbar {
    position: relative;
  }
  .Navbar :global .container {
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
}
