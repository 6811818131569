.TwoColumnText {
  display: flex;
  justify-content: center;
}
.TwoColumnText :global .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
}

.TwoColumnText :global h2 {
  color: #859c3e;
  text-align: center;
  width: 80%;
}

.TwoColumnText :global .item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}

.TwoColumnText :global p {
  width: 50%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.TwoColumnText :global .light {
  color: white;
}

.TwoColumnText :global h2.dark {
  color: #859c3e;
}

.TwoColumnText :global p.dark {
  color: #000000;
}

@media (max-width: 769px) {
  .TwoColumnText :global .item {
    flex-direction: column;
    align-items: center;
  }

  .TwoColumnText :global p {
    width: 100%;
  }
}
