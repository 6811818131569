.Datenschutz :global .container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Datenschutz :global h2 {
  color: #859c3e;
  margin-top: 8%;
}

.Datenschutz :global h3 {
  width: 80%;
  margin-top: 5%;
  font-size: 1.5em;
}

.Datenschutz :global .headline {
  width: 80%;
  margin-top: 2%;
  margin-bottom: 0%;
  font-weight: 700;
}

.Datenschutz :global .content {
  margin-top: 1%;
  width: 80%;
  font-weight: 400;
}
