.ServiceOverview :global .container {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.ServiceOverview :global .item {
  width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ServiceOverview :global img {
  width: 1270px;
}

.ServiceOverview :global .SwooshLeft {
  align-self: flex-end;
}

.ServiceOverview :global .SwooshRight {
  align-self: flex-start;
}

.ServiceOverview :global .right {
  margin-top: 100px;
}

.ServiceOverview :global .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ServiceOverview :global p {
  margin-top: 5%;
  margin-bottom: 10%;
  width: 60%;
}

@media (max-width: 1100px) {
  .ServiceOverview :global .container {
    flex-direction: column;
  }
  .ServiceOverview :global .item {
    width: 100%;
  }
  .ServiceOverview :global .SwooshLeft {
    align-self: flex-end;
    margin-right: 10%;
  }
  .ServiceOverview :global .SwooshRight {
    align-self: flex-start;
    margin-left: 10%;
  }
  .ServiceOverview :global p {
    width: 80%;
    margin-bottom: 5%;
  }
}

@media (max-width: 580px) {
  .ServiceOverview :global img {
    width: 1000px;
  }
}

@media (max-width: 480px) {
  .ServiceOverview :global img {
    width: 800px;
  }
}
@media (max-width: 375px) {
  .ServiceOverview :global img {
    width: 620px;
  }
}

/* .ServiceOverview :global .item {
  width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ServiceOverview :global img {
  height: 280px;
  width: 1296px;
}

.ServiceOverview :global .right {
  margin-top: 15%;
}

.ServiceOverview :global .SwooshLeft {
  float: right;
}

.ServiceOverview :global .SwooshRight {
  float: left;
}

.ServiceOverview :global p {
  margin-top: 2px;
}

@media only screen and (min-width: 2600px) {
  .ServiceOverview :global .SwooshLeft {
    float: left;
  }

  .ServiceOverview :global .SwooshRight {
    float: right;
  }
} */
