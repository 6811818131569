.Footer {
}

.Footer :global .container {
  margin-top: 100px;
  padding: 3% 0% 1% 0%;
  display: flex;
  background-color: #859c3e;
  justify-content: space-around;
  color: white;
  height: fit-content;
  margin-bottom: 0px;
  width: 100%;
  align-self: flex-end;
}

.Footer :global .item {
  display: flex;
  flex-direction: column;
  /*margin: 1% 5%;*/
}

.Footer :global h3 {
  font-size: 2em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Footer :global p {
  line-height: 30px;
}

.Footer :global .title {
  display: flex;
  align-items: center;
  text-align: center;
}

.Footer :global .extras {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Footer :global button {
  border: 3px solid transparent;
  font-weight: 400;
}

.Footer :global button:hover {
  color: rgb(219, 219, 219);
}

@media (max-width: 940px) {
  .Footer :global .container {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    width: 95%;
  }

  .Footer :global .item:nth-of-type(1) {
    order: 2;
  }
  .Footer :global .item:nth-of-type(2) {
    order: 1;
  }
  .Footer :global .item:nth-of-type(3) {
    order: 3;
  }
  .Footer :global .extras {
    flex-direction: column;
    align-items: flex-start;
    margin-left: -20px;
  }
}
