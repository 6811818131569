.Title :global .container {
  display: flex;
  margin-top: 150px;
  justify-content: center;
}

.Title :global .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title :global h2 {
  color: #859c3e;
  /* margin-top: 150px; */
}

.Title :global p {
  width: 60%;
  margin-top: 40px;
  text-align: justify;
}

/* For desktops: */
.Title :global .col-1 {
  width: 8.33%;
}
.Title :global .col-2 {
  width: 16.66%;
}
.Title :global .col-3 {
  width: 25%;
}
.Title :global .col-4 {
  width: 33.33%;
}
.Title :global .col-5 {
  width: 41.66%;
}
.Title :global .col-6 {
  width: 50%;
}
.Title :global .col-7 {
  width: 58.33%;
}
.Title :global .col-8 {
  width: 66.66%;
}
.Title :global .col-9 {
  width: 75%;
}
.Title :global .col-10 {
  width: 83.33%;
}
.Title :global .col-11 {
  width: 91.66%;
}
.Title :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  /* For mobile phones: */
  .Title :global [class*="col-"] {
    width: 100%;
  }

  .Title :global p {
    text-align: left;
    width: 80%;
  }
}

@media only screen and (max-width: 940px) {
  .Title :global .container {
    margin-top: 50px;
  }
}
