.Impressum :global .container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Impressum :global h2 {
  color: #859c3e;
  margin-top: 8%;
}

.Impressum :global .content {
  width: 80%;
  margin-top: 5%;
  line-height: 50px;
}
