.About {
  display: flex;
  justify-content: center;
}
.About :global .container {
  display: flex;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 0;
  width: 80%;
  justify-content: space-between;
}
.About :global h2 {
  color: #859c3e;
}
.About :global .item {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.About :global .text {
  width: 40%;
}

.About :global .image {
  width: 50%;
}

@media (max-width: 1100px) {
  .About :global .container {
    flex-direction: column;
  }
  .About :global .item {
    width: 100%;
  }

  .About :global h2 {
    text-align: center;
  }

  .About :global .text {
    margin-bottom: 0%;
    margin-top: 5%;
  }

  .About :global .image {
    width: 50%;
  }

  .About :global .item:nth-of-type(1) {
    order: 2;
  }
  .About :global .item:nth-of-type(2) {
    order: 1;
  }
}
