.Contact {
  display: flex;
  justify-content: center;
}

.Contact :global .container {
  display: flex;
  margin-top: 150px;
  width: 80%;
  flex-direction: column;
}

.Contact :global .section {
  display: flex;
  justify-content: space-between;
}

.Contact :global h2 {
  color: #859c3e;
  margin-bottom: 20px;
}

.Contact :global .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Contact :global .item.text {
  width: 50%;
}

.Contact :global .pia {
  width: 80%;
  align-self: flex-end;
}

.Contact :global .info {
  line-height: 50px;
}

@media (max-width: 1100px) {
  .Contact :global .section {
    flex-direction: column;
  }
  .Contact :global .item.text {
    width: 100%;
  }
  .Contact :global .pia {
    width: 60%;
    align-self: center;
  }
}

@media only screen and (max-width: 940px) {
  .Contact :global .container {
    margin-top: 50px;
  }
}
