.Category :global .container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5%;
}

.Category :global .right {
  flex-direction: row-reverse;
}

.Category :global .left {
  flex-direction: row;
}

.Category :global .item {
  /* margin-left: 2.5%;
  margin-right: 2.5%; */
  display: flex;
  flex-direction: column;
}

.Category :global .left .item.image {
  align-items: flex-start;
}

.Category :global .right .item.image {
  align-items: flex-end;
}

.Category :global .img {
  width: 90%;
  height: auto;
}

.Category :global .content {
  width: 30%;
  padding-top: 2.5%;
}

.Category :global h2 {
  color: #859c3e;
}

.Category :global .underheadline {
  font-weight: 700;
}

.Category :global .notice {
  font-weight: 200;
  font-style: italic;
}

.Category :global .pricelist {
  width: 100%;
}

.Category :global .split {
  display: block;
}

.Category :global .split span {
  display: block;
  float: right;
  color: #859c3e;
}

.Category :global .contact {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.Category :global .voucher {
  display: flex;
  justify-content: left;
  margin-top: 20px;
  margin-bottom: 40px;
}
.Category :global button {
  margin-left: 0px;
  margin-right: 20px;
}

@media (max-width: 1100px) {
  .Category :global .container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15%;
  }
  .Category :global .img {
    display: none;
  }
  .Category :global .content {
    width: 80%;
  }
  .Category :global .left .item.image {
    align-items: center;
  }

  .Category :global .right .item.image {
    align-items: center;
  }
  .Category :global .pricelist {
    width: 100%;
  }
  .Category :global h2 {
    text-align: center;
  }
}
