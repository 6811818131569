.FullMenu :global .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7.5%;
  /*border-style: solid;
  border-color: rgb(255, 0, 85);*/
  width: 100vw;
  box-sizing: border-box;
}

.FullMenu :global .row {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  /*border-style: solid;
  border-color: rgb(0, 247, 255);*/
  width: 100vw;
}

.FullMenu :global .column {
  display: flex;
  flex-direction: column;
  /*border-style: solid;
  border-color: rgb(9, 218, 37);*/
  width: 50vw;
  margin-bottom: 100px;
}

.FullMenu :global img.SwooshLeft,
.FullMenu :global img.SwooshRight {
  width: 1270px;
}

.FullMenu :global .SwooshLeft {
  align-self: flex-end;
  margin-right: 5%;
}

.FullMenu :global .SwooshRight {
  align-self: flex-start;
  margin-left: 5%;
}

.FullMenu :global .dessert {
  padding-top: 50px;
}

.FullMenu :global .right {
  margin-top: 100px;
}

.FullMenu :global .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-inline-start: 0px;
  padding-left: 15%;
  padding-right: 15%;
  margin-top: 5%;
}

.FullMenu :global .item {
  display: flex;
  width: 100%;
}

.FullMenu :global .veggie {
  width: 24px;
  height: auto;
  padding-right: 10px;
  align-self: flex-start;
}

.FullMenu :global .firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FullMenu :global .text {
  width: 100%;
}

.FullMenu :global p.name {
  font-weight: 700;
  margin-bottom: 0px;
}

.FullMenu :global p.description {
  font-weight: 200;
}

@media (max-width: 1100px) {
  .FullMenu :global .row {
    flex-direction: column;
  }
  .FullMenu :global .column {
    width: 100vw;
  }
  .FullMenu :global .right {
    margin-top: 0px;
  }
  .FullMenu :global .SwooshLeft {
    align-self: flex-end;
    margin-right: 15%;
  }

  .FullMenu :global .SwooshRight {
    align-self: flex-start;
    margin-left: 15%;
  }
  .FullMenu :global .dessert {
    padding-top: 0px;
  }
}

@media (max-width: 580px) {
  .FullMenu :global img.SwooshLeft,
  .FullMenu :global img.SwooshRight {
    width: 1000px;
  }
}

@media (max-width: 500px) {
  .FullMenu :global img.SwooshLeft,
  .FullMenu :global img.SwooshRight {
    width: 800px;
  }
}
@media (max-width: 400px) {
  .FullMenu :global img.SwooshLeft,
  .FullMenu :global img.SwooshRight {
    width: 620px;
  }
}
