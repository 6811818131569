.Menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Menu :global .infos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-top: 2.5%;
  /*border-style: solid;
  border-color: rgb(255, 0, 85);*/
}

.Menu :global .item {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  /*border-style: solid;
  border-color: aqua;*/
}

.Menu :global .icon {
  height: 24px;
  margin-right: 10px;
}

.Menu :global p {
  margin: 0;
}

@media (max-width: 1100px) {
  .Menu :global .infos {
    flex-direction: column;
    gap: 10px;
    align-self: flex-start;
    margin-left: 10%;
  }
  .Menu :global .item {
    justify-content: left;
  }
}
