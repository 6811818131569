.Header :global .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/Home/Home_Backgound.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 8%;
  padding-bottom: 5%;
}

.Header :global .item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Header :global .image {
  width: 630px;
}

.Header :global h1 {
  font-family: Tinos;
  color: white;
  font-size: 6em;
  margin: 0;
}

.Header :global h1 .taste {
  font-family: Tinos;
  color: #859c3e;
  font-size: 2.3em;
}

.Header :global .HomeCurryImg {
  width: 100%;
  height: auto;
  margin-top: 10%;
  object-fit: cover;
  object-position: right center;
}

@media (max-width: 600px) {
  .Header :global h1 {
    font-size: 4em;
  }
}

@media (max-width: 375px) {
  .Header :global h1 {
    font-size: 3em;
  }
}
